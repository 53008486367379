import React, { useEffect, useContext } from 'react';
import ServiceCard from '../components/ServiceCard';
import image1 from '../assets/images/ux-interface.png';
import image2 from '../assets/images/app-development.png';
import image3 from '../assets/images/coding.png';
import image4 from '../assets/images/cloud.png';

import image5 from '../assets/images/ux-interface-light.png';
import image6 from '../assets/images/app-development-light.png';
import image7 from '../assets/images/coding-light.png';
import image8 from '../assets/images/cloud-light.png';

import '../styles/Services.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AuthContext } from '../navigation/AuthProvider';

const Services = () => {
	const { theme } = useContext(AuthContext);
	useEffect(() => {
		AOS.init({
			duration: 1000,
		});
	}, []);

	return (
		<section className='services-container' id='services' data-aos='fade-left'>
			<p className='text'>OUR SERVICES</p>
			<h4 className='bold'>
				Streamline Your Business with Tailored Software Solutions
			</h4>
			<div className='flex'>
				<ServiceCard
					image={theme === 'dark-theme' ? image1 : image5}
					label={'UX/UI Designer'}
					description={
						'Crafting visually stunning and user-friendly interfaces that captivate your audience and drive engagement.'
					}
				/>
				<ServiceCard
					image={theme === 'dark-theme' ? image2 : image6}
					label={'App Development'}
					description={
						'Building high-performance mobile apps with intuitive navigation and seamless functionality to grow your business.'
					}
				/>
				<ServiceCard
					image={theme === 'dark-theme' ? image3 : image7}
					label={'Web Development'}
					description={
						'Creating responsive, SEO-optimized sites that deliver exceptional user experiences and boost your online presence.'
					}
				/>
				<ServiceCard
					image={theme === 'dark-theme' ? image4 : image8}
					label={'Hosting & Domain'}
					description={
						'Reliable hosting and custom domain solutions to ensure your business stays connected and secure.'
					}
				/>
			</div>
		</section>
	);
};

export default Services;
