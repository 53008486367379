import React, { useState } from 'react';
import Button from '../components/Button';
import FormModal from '../components/FormModal';
import Image from '../assets/images/pakata-goh_1.jpg';
import '../styles/Main.css';

const Main = () => {
	const [visible, setVisible] = useState(false);

	const onPressed = () => {
		setVisible(!visible);
	};
	return (
		<>
			<section className='main' data-aos='fade-right'>
				<div className='textContainer'>
					<p className='text'>Your Trusted Partner in Custom Software Development</p>
					<h4 className='bold'>Transforming Ideas into Scalable, User-Friendly Solutions</h4>
					<p className='secondText'>
						We deliver cutting-edge custom software solutions designed to help you start, scale, and succeed. 
						From intuitive web and mobile apps to seamless hosting services, we’re here to turn your vision into reality. 
						Ready to elevate your business? Let’s build something amazing together.
					</p>
					<Button title={"LET'S TALK"} onPressed={() => onPressed()} />
				</div>
				<div className='imageContainer'>
					<img src={Image} alt='main' className='image' />
				</div>
			</section>
			<FormModal close={visible} />
		</>
	);
};

export default Main;
